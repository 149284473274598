.musical-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
   
  }
  
  @media (max-width: 768px) {
    .musical-grid-container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 12px;
  
    }
  }
  
  @media (max-width: 480px) {
    .musical-grid-container {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 8px;
    
    }
  }
  
  .musical-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .musical-image {
    width: 100%;
    object-fit: cover;
  }
  
  .musical-info {
    padding: 8px;
    text-align: center;
  }
  
  .musical-title {
    font-size: 1rem; /* 16px */
    font-weight: bold;
    color: #333;
    margin: 8px 0;
  }
  
  .musical-price {
    font-size: 0.875rem; /* 14px */
    color: #666;
  }
  